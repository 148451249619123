document.addEventListener("turbolinks:load", function() {
  var elements = document.querySelectorAll(".speech");
   Array.from(elements).forEach(function(element) {
     var newSpan = document.createElement("span");
     newSpan.innerText = "play_circle_filled";
     newSpan.classList.add("material-icons");
     element.appendChild(newSpan);

    newSpan.addEventListener("click", function() {
      text = this.parentNode.firstChild.data;
      var audio = new Audio("/audio?text=" + text);
      audio.play();
    });
  });
}, false);



