import * as wanakana from "wanakana/umd/wanakana";

document.addEventListener("turbolinks:load", function() {
  var elements = document.querySelectorAll(".wanakana");
   Array.from(elements).forEach(function(element) {
    wanakana.bind(element, /* options */);
  });
}, false);



